<script>
export default {
  metaInfo: {
    title: "Test Status",
  }
};
</script>

<script setup>
import DynamicTestStatusView from '@/components/dynamic/TestStatusView.vue';
</script>

<template>
  <DynamicTestStatusView/>
</template>

